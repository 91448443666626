
































import {defineComponent, onMounted, ref, Ref} from "@vue/composition-api";
import {axiosInstance} from "@/plugins/axios-instance";
import {API_PREFIX_MAIN} from "@/constants";
import {INews} from "@/pages/home/api/INews";

export default defineComponent({
	name: "HomeCardDetailsView",
	props: {
		news_id: {
			type: String
		}
	},
	setup(props) {
		/**
		 *
		 */
		const loading: Ref<boolean> = ref(false);

		/**
		 *
		 */
		const data: Ref<INews | null> = ref(null);

		/**
		 *
		 */
		async function fetchNews(): Promise<void> {
			loading.value = true;
			const response = await axiosInstance.get(`${API_PREFIX_MAIN}/news/${props.news_id}`);
			data.value = response.data;
			loading.value = false;
		}

		onMounted(() => {
			fetchNews();
		});

		return {
			loading,
			data
		}
	}
});
